var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex justify-content-end align-items-center"},[_c('AdvanceChartButton')],1)],1),_c('CRow',{staticClass:"mb-3"},[_c('CCol',[_c('LineChart',{attrs:{"chartData":{
          labels: _vm.chartPriceData.dates,
          datasets: [
            {
              label: 'Close Price',
              borderColor: '#0d6efd',
              data: _vm.chartPriceData.closePrices,
              fill: false,
              tension: 0,
              radius: 0,
              borderWidth: 3,
            },
          ],
        },"chartOptions":{
          interaction: {
            intersect: false,
            mode: 'index',
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: { display: true },
              title: {
                display: true,
                text: 'Price ( $ )',
                font: {
                  size: 16,
                  family: 'Roboto',
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
              type: 'time',
              title: {
                display: true,
                text: 'Dates',
                font: {
                  size: 16,
                  family: 'Roboto',
                },
                align: 'start',
              },
              time: {
                unit: 'day',
                stepSize: 15,
                displayFormats: {
                  day: 'dd-MM-yy',
                },
                round: 'day',
              },
            },
          },
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }