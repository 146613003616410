<template>
  <CRow class="mb-3">
    <CCol>
      <div>
        <div v-for="section in aboutWidgetSections" :key="section.label">
          <h6 v-if="section.value" class="mb-1">{{ section.label }}</h6>
          <p class="mb-2">{{ section.value || "" }}</p>
        </div>
      </div>
    </CCol>
  </CRow>
</template>
<script>
export default {
  name: "AboutWidget",
  props: {
    aboutWidgetData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    aboutWidgetSections() {
      return [
        { label: "Industry", value: this.aboutWidgetData.industryDescription },
        { label: "Sector", value: this.aboutWidgetData.sectorDescription },
        { label: "About", value: this.aboutWidgetData.description },
      ];
    },
  },
};
</script>
