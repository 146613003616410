<template>
  <div>
    <CRow class="mb-3">
      <CCol class="d-flex justify-content-end align-items-center">
        <AdvanceChartButton />
      </CCol>
    </CRow>
    <CRow class="mb-3">
      <CCol>
        <LineChart
          :chartData="{
            labels: chartPriceData.dates,
            datasets: [
              {
                label: 'Close Price',
                borderColor: '#0d6efd',
                data: chartPriceData.closePrices,
                fill: false,
                tension: 0,
                radius: 0,
                borderWidth: 3,
              },
            ],
          }"
          :chartOptions="{
            interaction: {
              intersect: false,
              mode: 'index',
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                grid: { display: true },
                title: {
                  display: true,
                  text: 'Price ( $ )',
                  font: {
                    size: 16,
                    family: 'Roboto',
                  },
                },
              },
              x: {
                grid: {
                  display: false,
                },
                type: 'time',
                title: {
                  display: true,
                  text: 'Dates',
                  font: {
                    size: 16,
                    family: 'Roboto',
                  },
                  align: 'start',
                },
                time: {
                  unit: 'day',
                  stepSize: 15,
                  displayFormats: {
                    day: 'dd-MM-yy',
                  },
                  round: 'day',
                },
              },
            },
          }" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import LineChart from "@/components/portfolios/charts/LineChart.vue";
import AdvanceChartButton from "@/components/buttons/advanceChartButton.vue";

export default {
  name: "chartPrice",
  components: {
    LineChart,
    AdvanceChartButton,
  },
  props: {
    chartPriceData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
