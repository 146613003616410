<template>
  <CRow class="mb-3">
    <CCol>
      <div>
        <h4 class="mb-0 h4">
          {{ quoteWidgetData.companyName || "" }} ({{
            quoteWidgetData.ticker || ""
          }})
        </h4>
        <div class="mb-0 text-muted">
          {{ quoteWidgetData.exchangeName || "" }}
        </div>
        <div>
          <span class="font-weight-bold display-4">{{
            quoteWidgetData.price || ""
          }}</span>
          <span class="text-left p-2">{{
            quoteWidgetData.currencyType || ""
          }}</span>
        </div>
<!--         <p class="fs-5 mb-0" :class="{ 'text-danger': numbers < 0 }">
          {{ formatNumber(numbers) || "" }}
        </p> -->
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "quoteWidget",
  props: {
    quoteWidgetData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      numbers: -0.24,
    };
  },

  methods: {
    formatNumber(number) {
      if (number < 0) {
        return "-" + Math.abs(number) + " " + "-" + Math.abs(number) + "% ▼";
      } else {
        return number + " " + number + "% ▲";
      }
    },
  },
};
</script>
<style scoped></style>
