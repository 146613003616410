<template>
  <CRow class="mb-3">
    <CCol>
    <div v-if="movingAveragesTableData.movingAveragesData.length > 0">
        <dynamicDataTableSellCondition
          title="Moving Averages"
          :dataTable="movingAveragesTableData.movingAveragesData"
        />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import dynamicDataTableSellCondition from "@/components/portfolios/dynamicDataTableSellCondition.vue";

export default {
  name: "movingAveragesTable",
  components: {
    dynamicDataTableSellCondition,
  },
  props: {
    movingAveragesTableData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.table td {
  line-height: 1.2;
}
</style>
