<template>
  <ShadowCard :param="{ name: 'Security Overview' }">
    <b-overlay :show="!isLoaded">
      <div v-if="dataAvailable">
        <CRow>
          <CCol lg="4">
            <quoteWidget :quoteWidgetData="quoteWidgetData" />
            <aboutWidget :aboutWidgetData="aboutWidgetData" />
            <keyDataTable :keyTableData="keyTableData" />
          </CCol>
          <CCol lg="8">
            <chartPrice :chartPriceData="chartPriceData" />
            <CRow>
              <CCol lg="6">
                <performanceTable
                  :performanceTableData="performanceTableData" />
                <movingAveragesTable
                  :movingAveragesTableData="movingAveragesTableData" />
              </CCol>
              <CCol lg="6">
                <identifiersTable
                  :identifiersTableData="identifiersTableData" />
                <technicalsTable :technicalsTableData="technicalsTableData" />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <div class="text-center">
          <p>No information available</p>
        </div>
      </div>
    </b-overlay>
    <div class="row">
      <BackButton />
    </div>
  </ShadowCard>
</template>

<script>
import ShadowCard from "@/presentationalComponents/ShadowCard.vue";
import aboutWidget from "./aboutWidget.vue";
import quoteWidget from "./quoteWidget.vue";
import keyDataTable from "./keyDataTable.vue";
import performanceTable from "./performanceTable.vue";
import movingAveragesTable from "./movingAveragesTable.vue";
import identifiersTable from "./identifiersTable.vue";
import technicalsTable from "./technicalsTable.vue";
import chartPrice from "./chartPrice.vue";
import numeral from "numeral";
import BackButton from "@/components/buttons/backButton.vue";

export default {
  name: "SecurityOverview",
  components: {
    ShadowCard,
    aboutWidget,
    quoteWidget,
    keyDataTable,
    performanceTable,
    movingAveragesTable,
    identifiersTable,
    technicalsTable,
    chartPrice,
    BackButton,
  },

  data() {
    return {
      quoteWidgetData: {},
      aboutWidgetData: {},
      keyTableData: {},
      chartPriceData: {},
      identifiersTableData: {},
      performanceTableData: {},
      movingAveragesTableData: {},
      technicalsTableData: {},
      dataLoaded: false,
    };
  },
  watch: {
    securityLastPrice: {
      handler(newVal) {
        this.objectsSecurityPrice(newVal);
      },
      deep: true, //Esto asegura que se observe el cambio en profundidad, para cambios en objetos anidados.
    },
    securityHistoricalPrice: {
      handler(newVal) {
        this.objectsSecurityHistoricalPrice(newVal);
      },
      deep: true,
    },
    securityReturnToDate: {
      handler(newVal) {
        this.objectsSecurityReturnToDate(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    if (
      this.securityLastPrice &&
      this.securityHistoricalPrice &&
      this.securityReturnToDate
    ) {
      this.objectsSecurityPrice(this.securityLastPrice);
      this.objectsSecurityHistoricalPrice(this.securityHistoricalPrice);
      this.objectsSecurityReturnToDate(this.securityReturnToDate);
    }
  },

  methods: {
    objectsSecurityPrice(newVal) {
      this.dates = [];

      for (const date in newVal.data) {
        this.dates.push(date.slice(0, 10));
        for (const identifier in newVal.data[date]) {
          const identifierData = newVal.data[date][identifier][0];

          this.quoteWidgetData = {
            companyName: identifierData?.metadata?.security?.name,
            ticker: identifierData?.metadata?.security?.ticker,
            exchangeName: identifierData?.metadata?.security?.exchange?.name,
            price: identifierData?.closePrice,
            currencyType: identifierData?.metadata?.security?.currency?.isoCode,
          };

          this.aboutWidgetData = {
            industryDescription:
              identifierData?.metadata?.security?.company?.gics?.name,
            sectorDescription:
              identifierData?.metadata?.security?.company?.gics?.parent?.parent
                ?.parent?.name,
            description: identifierData?.metadata?.security?.description,
          };

          this.keyTableData = {
            keyData: [
              {
                label: "Close",
                value: identifierData.closePrice
                  ? numeral(identifierData.closePrice).format("0,0.00")
                  : "-",
              },
              {
                label: "Open",
                value: identifierData.openPrice
                  ? numeral(identifierData.openPrice).format("0,0.00")
                  : "-",
              },
              {
                label: "High",
                value: identifierData.highPrice
                  ? numeral(identifierData.highPrice).format("0,0.00")
                  : "-",
              },
              {
                label: "Low",
                value: identifierData.lowPrice
                  ? numeral(identifierData.lowPrice).format("0,0.00")
                  : "-",
              },
              {
                label: "Volume",
                value: identifierData.metadata.priceData.volume
                  ? numeral(identifierData.metadata.priceData.volume).format(
                      "0,0"
                    )
                  : "-",
              },
              {
                label: "Market Cap",
                value: identifierData.metadata.priceData.marketCap
                  ? numeral(
                      identifierData.metadata.priceData.marketCap * 1000000
                    )
                      .format("$0.00a")
                      .toUpperCase()
                  : "-",
              },
              {
                label: "P/E Ratio",
                value: identifierData.metadata.priceData.trailPe
                  ? numeral(identifierData.metadata.priceData.trailPe).format(
                      "0,0.0000"
                    )
                  : "-",
              },
              {
                label: "P/B Ratio",
                value: identifierData.metadata.priceData.pbRatio
                  ? numeral(identifierData.metadata.priceData.pbRatio).format(
                      "0,0.0000"
                    )
                  : "-",
              },
              {
                label: "Beta",
                value: identifierData.metadata.priceData.beta
                  ? numeral(identifierData.metadata.priceData.beta).format(
                      "0,0.00"
                    )
                  : "-",
              },
              {
                label: "Dividend Yield",
                value: identifierData.metadata.priceData.dvdYld
                  ? numeral(identifierData.metadata.priceData.dvdYld).format(
                      "0,0.00"
                    )
                  : "-",
              },
              {
                label: "Earning Yield",
                value: identifierData.metadata.priceData.earnYld
                  ? numeral(identifierData.metadata.priceData.earnYld).format(
                      "0,0.00"
                    )
                  : "-",
              },
              {
                label: "ROE",
                value: identifierData.metadata.priceData.leadRoe
                  ? numeral(identifierData.metadata.priceData.leadRoe).format(
                      "0,0.000"
                    )
                  : "-",
              },
              {
                label: "ROA",
                value: identifierData.metadata.priceData.leadRoa
                  ? numeral(identifierData.metadata.priceData.leadRoa).format(
                      "0,0.000"
                    )
                  : "-",
              },
              {
                label: "Free Cash Flow",
                value: identifierData.metadata.priceData.fcf
                  ? numeral(identifierData.metadata.priceData.fcf).format(
                      "$0,0.000"
                    ) + " B"
                  : "-",
              },
            ],
          };

          this.identifiersTableData = {
            identifiersData: [
              {
                label: "FIGI",
                value: identifierData.metadata.security.figi
                  ? identifierData.metadata.security.figi
                  : "-",
              },
              {
                label: "ISIN",
                value: identifierData.metadata.security.isin
                  ? identifierData.metadata.security.isin
                  : "-",
              },
              {
                label: "CUSIP",
                value: identifierData.metadata.security.cusip
                  ? identifierData.metadata.security.cusip
                  : "-",
              },
              {
                label: "SEDOL",
                value: identifierData.metadata.security.sedol1
                  ? identifierData.metadata.security.sedol1
                  : "-",
              },
            ],
          };

          this.movingAveragesTableData = {
            movingAveragesData: [
             /*  {
                label: "MA5",
                value: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA5
                  : "-",
                condition: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA5.Sell
                  : "-",
              },
              {
                label: "MA10",
                value: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA10
                  : "-",
                condition: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA10.Sell
                  : "-",
              },
              {
                label: "MA20",
                value: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA20
                  : "-",
                condition: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA20.Sell
                  : "-",
              },
              {
                label: "MA50",
                value: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA50
                  : "-",
                condition: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA50.Sell
                  : "-",
              },
              {
                label: "MA100",
                value: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA100
                  : "-",
                condition: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA5.Sell
                  : "-",
              },
              {
                label: "MA200",
                value: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA200
                  : "-",
                condition: identifierData.metadata.movingAverages
                  ? identifierData.metadata.movingAverages.MA5.Buy
                  : "-",
              }, */
            ],
          };

          this.technicalsTableData = {
            technicalsData: [
              /* {
                label: "RSI(14)",
                value: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData["RSI(14)"]
                  : "-",
                condition: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData.Sell
                  : "-",
              },
              {
                label: "MACD(12,26)",
                value: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData["MACD(12,26)"]
                  : "-",
                condition: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData.Sell
                  : "-",
              },
              {
                label: "ADX(14)",
                value: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData["ADX(14)"]
                  : "-",
                condition: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData.Sell
                  : "-",
              },
              {
                label: "STOCH(9,16)",
                value: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData["STOCH(9,16)"]
                  : "-",
                condition: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData.Sell
                  : "-",
              },
              {
                label: "ROC",
                value: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData["ROC"]
                  : "-",
                condition: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData.Sell
                  : "-",
              },
              {
                label: "ATR(14)",
                value: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData["ATR(14)"]
                  : "-",
                condition: identifierData.metadata.technicalsData
                  ? identifierData.metadata.technicalsData.LessVol
                  : "-",
              }, */
            ],
          };
        }
      }
    },

    objectsSecurityHistoricalPrice(newVal) {
      this.dates = [];
      this.closePrices = [];

      for (const date in newVal.data) {
        this.dates.push(date.slice(0, 10));
        for (const identifier in newVal.data[date]) {
          const closePriceObj = newVal.data[date][identifier];
          const closePrice = closePriceObj[0].closePrice;
          this.closePrices.push(closePrice);
        }
      }

      this.chartPriceData = {
        dates: this.dates,
        closePrices: this.closePrices,
      };
    },

    objectsSecurityReturnToDate(newVal) {
      this.dates = [];

      for (const date in newVal.body.data) {
        this.dates.push(date.slice(0, 10));
        for (const identifier in newVal.body.data[date]) {
          const identifierData = newVal.body.data[date][identifier][0];

          this.performanceTableData = {
            performanceData: [
              {
                label: "WTD",
                value: identifierData.wtd
                  ? numeral(identifierData.wtd).format("0,0.00") + "%"
                  : "-",
              },
              {
                label: "MTD",
                value: identifierData.mtd
                  ? numeral(identifierData.mtd).format("0,0.00") + "%"
                  : "-",
              },
              {
                label: "QTD",
                value: identifierData.qtd
                  ? numeral(identifierData.qtd).format("0,0.00") + "%"
                  : "-",
              },

              {
                label: "YTD",
                value: identifierData.ytd
                  ? numeral(identifierData.ytd).format("0,0.00") + "%"
                  : "-",
              },
            ],
          };
        }
      }
    },
  },

  computed: {
    securityLastPrice() {
      return this.$store.state.securityLastPrice;
    },
    securityHistoricalPrice() {
      return this.$store.state.securityHistoricalPrice;
    },
    securityReturnToDate() {
      return this.$store.state.securityReturnToDate;
    },
    isLoaded() {
      return Object.keys(this.securityLastPrice).length > 0;
    },
    dataAvailable() {
      return (
        this.securityLastPrice.data &&
        Object.keys(this.securityLastPrice.data).length > 0
      );
    },
  },
};
</script>

<style></style>
