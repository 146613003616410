<template>
  <CRow class="mb-3">
    <CCol>
      <div v-if="identifiersTableData.identifiersData.length > 0">
        <dynamicDataTable
          title="Identifiers"
          :dataTable="identifiersTableData.identifiersData"
        />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import dynamicDataTable from "@/components/portfolios/dynamicDataTable.vue";

export default {
  name: "identifiersTable",
  components: {
    dynamicDataTable,
  },
  props: {
    identifiersTableData: {
      type: Object,
      required: true,
    },
  },
};
</script>
