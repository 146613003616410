<template>
  <CRow class="mb-3">
    <CCol>
      <div v-if="keyTableData.keyData.length > 0">
        <dynamicDataTable title="Key Data" :dataTable="keyTableData.keyData" />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import dynamicDataTable from "@/components/portfolios/dynamicDataTable.vue";

export default {
  name: "keyDataTable",
  components: {
    dynamicDataTable,
  },
  props: {
    keyTableData: {
      type: Object,
      required: true,
    },
  },
};
</script>
