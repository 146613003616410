<template>
  <button
    class="btn px-3 font-weight-bold text-white rounded-1 btn-primary"
    @click="$router.push({ name: 'AdvancedChartView' })">
    Advanced Chart
  </button>
</template>

<script>
export default {
  name: "advanceChartButton",
};
</script>

<style>
/* Primary Button */

@media screen and (max-width: 576px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.btn-primary {
  font-size: 12px;
  line-height: 1.5;
  border-color: #273a5c;
  background-color: #273a5c;
}

.btn-primary:hover {
  border-color: #6c757d;
  background-color: #6c757d;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: #273a5c;
  -webkit-box-shadow: 0 0 0 0.2rem white;
  box-shadow: 0 0 0 0.2rem white;
}
</style>
