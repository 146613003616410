<template>
  <CRow>
    <CCol>
      <h6 class="py-0 pl-0 mb-1">{{ title }}</h6>
      <table class="table text-black">
        <tbody>
          <tr
            class="border-top-0 border-left-0 border-right-0 border-bottom border-dark"
            v-for="item in dataTable"
            :key="item.label"
          >
            <td class="text-left pt-1 pb-0 pl-0 border-top-0">
              {{ item.label }}
            </td>
            <td class="text-right font-weight-bold pt-1 pb-0 pr-0 border-top-0">
              {{ item.value }}
            </td>
          </tr>
        </tbody>
      </table>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "dynamicDataTable",
  props: {
    title: {
      type: String,
      required: true,
    },
    dataTable: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((item) => {
          return typeof item === "object" && item !== null;
        });
      },
    },
  },
};
</script>

<style scoped>
.table td {
  border-top: 1px solid rgb(0 0 0);
  line-height: 1.2;
}
.border-bottom {
  border-bottom: 1px solid rgb(0 0 0) !important;
}
.text-color {
  color: rgb(60, 75, 100);
}
</style>
