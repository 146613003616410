<template>
  <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import "chartjs-adapter-date-fns";

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartOptions: {
      type: Object,
      default: () => {},
    },
    chartData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
