<template>
  <CRow class="mb-3">
    <CCol>
      <div v-if="performanceTableData.performanceData.length > 0">
        <dynamicDataTableNegativeValues
          title="Performance"
          :dataTable="performanceTableData.performanceData"
        />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import dynamicDataTableNegativeValues from "@/components/portfolios/dynamicDataTableNegativeValues.vue";

export default {
  name: "performanceTable",
  components: {
    dynamicDataTableNegativeValues,
  },
  props: {
    performanceTableData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.table td {
  border-top: 1px solid rgb(0 0 0);
  line-height: 1.2;
}
.border-bottom {
  border-bottom: 1px solid rgb(0 0 0) !important;
}
</style>
